function timesToDate(times) {
  if (times) {
    let date = new Date(times)
    let y = date.getFullYear()
    let m = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
    let d = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let f = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return y + '-' + m + '-' + d + ' ' + h + ':' + f + ':' + s
  } else {
    return ''
  }
}

export function splitTDate(times) {
  if (times) {
    return times.split('T')[0]
  } else {
    return ''
  }
}

export function splitTDateTime(times) {
  if (times) {
    return times.split('T')[0] + ' ' + times.split('T')[1]
  } else {
    return ''
  }
}

export default {
  timesToDate
}
