<template>
    <div class="admin_page_body">
        <div class="admin_page_item pr0">
            <div class="data_search">
                <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="105px">
                    <el-form-item label="商品名称：" prop="name">
                        <el-input v-model="listQuery.name" placeholder="请输入商品名称" @keyup.enter.native="toSearchList"></el-input>
                    </el-form-item>

                    <el-form-item label="商品sku：" prop="skuCode">
                        <el-input v-model="listQuery.skuCode" placeholder="请输入商品sku编码" @keyup.enter.native="toSearchList"></el-input>
                    </el-form-item>

                    <el-form-item class="last">
                        <el-button type="primary" :loading="isSearchLoading"  @click="toSearchList">查 询</el-button>
                        <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div class="data_group mt18">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="$router.push('/admin/goods/add')">发布新商品</el-button>
            </div>

            <div class="data_alert mt18">
                <div class="data_alert_item">
                    <i class="el-icon-warning el-icon--left icon"></i>
                    <div class="text">已选择 <span>{{ids.length}}</span> 项 </div>
                    <el-button type="text" @click="toChangeStatus(0, '', '商品下架')">"商品下架"</el-button>
                </div>
            </div>

            <div class="data_table mt24">
                <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading" @selection-change="idsChange">
                    <el-table-column type="selection" width="50" align="center"></el-table-column>
                    <el-table-column prop="date" label="商品名称" min-width="240px">
                        <template slot-scope="scope">
                            <div class="goods_item">
                                <el-image :src="imageURL + scope.row.imageUrl" class="image" fit="contain"></el-image>
                                <div class="goods_item_info">
                                    <div class="name">{{scope.row.name}}</div>
                                    <div class="text">SPU:{{scope.row.id}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="规格/规格数量" min-width="240px" align="center">
                        <template slot-scope="scope">
                            <div class="goods_class">
                                <div class="text" v-for="(sku, index) in scope.row.skuType" :key="index">
                                    {{sku.skukey}}：<template v-for="(value, i) in sku.skuvalue"><template v-if="Number(i) > 0">，</template>{{value}}</template>
                                </div>
                                <el-button type="text" class="btn" @click="toGetGoodsInfo(scope.row.id, scope.row.name)">查看全部规格</el-button>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="价格" width="120" align="center">
                        <template slot-scope="scope">￥ {{scope.row.price}}</template>
                    </el-table-column>
                    <el-table-column prop="stock" label="库存" width="100" align="center"></el-table-column>
                    <el-table-column prop="address" label="销量" min-width="100px" align="center">
                        <template slot-scope="scope">{{scope.row.sales ? scope.row.sales : 0}}</template>
                    </el-table-column>
                    <el-table-column prop="address" label="创建时间" min-width="180px" align="center">
                        <template slot-scope="scope">{{scope.row.createdAt}}</template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" @click="$router.push({path: '/admin/goods/editSell', query: {id: scope.row.id}})">编辑</el-button>
                            <el-button type="text" @click="delData(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>

        <el-dialog class="popup_dialog" :title="skuTitle" :visible.sync="isPopup" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="1000px">
            <el-form label-width="52px" size="small" class="data_table">
                <el-form-item label-width="0">
                    <el-table :data="skuData" style="width: 100%" v-loading="isRequestSkuLoading">
                        <el-table-column label="商品规格">
                            <template slot-scope="scope">
                                <div class="goods_item" style="align-items: center">
                                    <el-image :src="imageURL + scope.row.imageUrl" class="image" fit="contain"></el-image>
                                    <div class="goods_class">
                                        <div class="text" style="line-height: 16px">
                                            <span class="ml16" v-for="(sku, index) in scope.row.sku" :key="index">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="价格" width="160" align="center">
                            <template slot-scope="scope">￥ {{scope.row.price}}</template>
                        </el-table-column>
                        <el-table-column prop="stock" label="库存" width="140" align="center"></el-table-column>
                        <el-table-column prop="skuCode" label="商家编码" width="160"></el-table-column>
                    </el-table>
                </el-form-item>

                <el-form-item>
                    <el-button @click="isPopup = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
  import {getGoods, postGoodsBatch, delGoods} from "../../../api/admin/goods";
  import {mapState} from "vuex";
  import {imageURL} from "../../../utils/config";
  import {splitTDate} from "../../../utils/dateFormat";
  const pagination = () => import('../../../components/pagination/admin')
  export default {
    name: "adminGoodsSell",
    data () {
      return {
        listQuery: {
          name: '',
          skuCode: '',
          type: 0,
          queryType: 1,
          review: '1',
          status: '1',
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        isSearchLoading: false,
        isRequestLoading: false,
        listData: [],
        skuData: [],
        skuTitle: '',
        ids: [],
        isPopup: false,
        isRequestSkuLoading: false,
        imageURL: imageURL,
        splitTDate: splitTDate
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getGoods(this.listQuery).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      toGetGoodsInfo (id, name) {
        this.skuTitle = name
        this.isPopup = true
        this.isRequestSkuLoading = true
        getGoods({
          queryType: 1,
          goodsId: id,
          page: 1,
          pageSize: 100
        }).then(res => {
          this.isRequestSkuLoading = false
          if (res.code === 0) {
            this.skuData = res.data.records
          }
        }).catch(() => {
          this.isRequestSkuLoading = false
        })
      },
      idsChange (val) {
        let ids = []
        val.forEach(item => {
          ids.push(item.id)
        })
        this.ids = ids
      },
      toChangeStatus (status, review, label) {
        if (this.ids.length === 0) {
          this.$message({
            message: '请选择商品',
            type: 'warning',
            duration: 2 * 1000
          })
          return false;
        }
        this.$confirm('是否确认' + label + '?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postGoodsBatch({
            data: {
              ids: this.ids,
              review: review,
              status: status
            }
          }).then(res => {
            if (res.code === 0) {
              this.$message({
                message: label + '成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      delData (id) {
        this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delGoods(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '商品删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {pagination}
  }
</script>
